
export const expSet = [
    {
        id: 1,
        name: 'React_developer',
        company: 'Local Shop India',
        year:'2022',
        desc:'Currently work as a intern in LSI. I am working on the frontend of the website. I am using React.js for the frontend.'
    },
    
];